import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const layoutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Layout = ({ children, lang }) => {
  return (
    <StaticQuery
      query={`${layoutQuery}`}
      render={(data) => (
        <div className='flex min-h-screen flex-col bg-gray-100 text-gray-800 antialiased'>
          <main role='main' id='main'>
            {children}
          </main>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.string
}

export default Layout
