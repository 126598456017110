import React from 'react'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

export const Seo = ({ authorData, children, datePublished, dateModified, description, image, isBlogPost, pathname, title }) => {
  const { companyName, description: defaultDescription, image: defaultImage, logo, twitterUsername, siteUrl } = useSiteMetadata()
  if (image) {
    image = `${image?.split('?')[0]}?format&q=&auto=compress&fit=crop&w=1200&h=630&txt=${title
      ?.split(' ')
      .join('+')}&txt-shad=5&txt-pad=30&txt-color=FFFFFF&txt-align=middle,center&txt-size=100&txt-fit=max&txt-font=Helvetica,Bold&border=5,000000&pad=10`
  }
  const seo = {
    companyName,
    description: description || defaultDescription,
    image: image || `${siteUrl}${defaultImage}`,
    logo: `${siteUrl}${logo}`,
    title: title || companyName,
    twitterUsername,
    url: `${siteUrl}${pathname || ``}`
  }
  const author = {
    name: authorData?.name || twitterUsername,
    image: authorData?.image.url,
    url: `${siteUrl}${authorData?.url ? authorData.url : ''}`
  }

  const baseSchema = [
    {
      '@context': 'https://schema.org/',
      '@type': 'WebSite',
      url: seo.url,
      name: seo.companyName,
      description,
      sameAs: ['https://www.facebook.com/thejuniperstudiouk/', 'https://twitter.com/studiojuniper', 'https://www.linkedin.com/company/the-juniper-studio']
    },
    {
      '@context': 'https://schema.org/',
      '@type': 'Organization',
      url: seo.url,
      name: seo.companyName,
      sameAs: ['https://www.facebook.com/thejuniperstudiouk/', 'https://twitter.com/studiojuniper', 'https://www.linkedin.com/company/the-juniper-studio'],
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Canterbury',
        postalCode: 'CT1 3SB',
        streetAddress: '37 Hollow Lane'
      },
      logo: {
        '@type': 'ImageObject',
        url: seo.logo
      }
    }
  ]

  const schema = isBlogPost
    ? [
        ...baseSchema,
        {
          '@context': 'https://schema.org/',
          '@type': ['BlogPosting', 'NewsArticle', 'Article'],
          url: seo.url,
          name: seo.companyName,
          headline: seo.title,
          image: [seo.image],
          description: seo.description,
          author: {
            '@type': 'Person',
            name: author.name,
            url: author.url
          },
          publisher: {
            '@type': 'Organization',
            url: seo.url,
            logo: {
              '@type': 'ImageObject',
              url: author.image
            },
            name: author.name
          },
          mainEntityOfPage: {
            '@type': 'WebSite',
            '@id': seo.url
          },
          datePublished: datePublished,
          dateModified
        }
      ]
    : baseSchema

  return (
    <React.Fragment key='seo'>
      <title>{seo.title}</title>
      <meta name='author' content={author.name} />
      <link rel='canonical' href={seo.url} data-baseprotocol='https:' data-basehost={siteUrl} />
      <meta name='description' content={seo.description} />
      <meta name='image' content={seo.image} />
      <meta name='og:title' content={seo.title} />
      <meta name='og:description' content={seo.description} />
      <meta name='og:image' content={`${seo.image}`} />
      <meta name='og:image:width' content='1200' />
      <meta name='og:image:height' content='630' />
      <meta name='og:type' content='website' />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:url' content={seo.url} />
      <meta name='twitter:description' content={seo.description} />
      <meta name='twitter:image' content={`${seo.image}`} />
      <meta name='twitter:creator' content={seo.twitterUsername} />
      <script type='application/ld+json'>{JSON.stringify(schema)}</script>
      {children}
    </React.Fragment>
  )
}

export default Seo
